import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Heading, Box, Grid } from "theme-ui"
import AuthorCard from "../components/AuthorCard"
import { removeDuplicates } from "../helpers"
import Container from "../components/Container"

const Authors = ({ location, data }) => {
  const {
    allBlog: { nodes },
  } = data

  const authors = nodes.map(({ author }) => author)

  return (
    <Layout location={location} title={"Newsroom Authors"}>
      <Seo
        title="Newsroom Authors"
        description="View all the different authors @ GoNation Newsroom!"
      ></Seo>
      <Box
        sx={{ padding: 3, minHeight: "100vh", pt: [3, 4, 4, 5] }}
        as="section"
      >
        <Container width={1200}>
          <Heading
            as="h1"
            sx={{
              mb: 4,
              color: "text",
              fontSize: [5, 5, 5, 6, 6],
              fontWeight: 400,
              borderBottom: "3px solid",
              borderColor: "primary",
            }}
          >
            Search Articles By Authors
          </Heading>
          <Grid
            sx={{
              gridWrap: "wrap",
              gridTemplateColumns: [
                "1fr",
                "1fr",
                "1fr 1fr",
                "1fr 1fr 1fr",
                "1fr 1fr 1fr 1fr",
              ],
            }}
          >
            {removeDuplicates(authors).map(author => (
              <AuthorCard tag={author} author={author} />
            ))}
          </Grid>
        </Container>
      </Box>
    </Layout>
  )
}

export default Authors

export const al = graphql`
  query {
    allBlog {
      nodes {
        author
      }
    }
  }
`
