import React from "react"
import { Link } from "gatsby"
import { Card, Box, AspectImage, Heading } from "theme-ui"
import { slugifyLower } from "../helpers"

const AuthorCard = ({ author }) => {
  const titles = [
    "CEO",
    "Customer Support",
    "Writer",
    "GN Ambassador",
    "Senior Customer Relationship Manager",
    "2021 Intern",
    "Project Leader",
  ]
  const pickRandomTitle = () => titles[Math.floor(Math.random() * 7)]
  return (
    <Link to={`${slugifyLower(author)}`}>
      <Card
        sx={{
          display: "flex",
          alignItems: "stretch",
          cursor: "pointer",
        }}
      >
        <Box sx={{ width: "25%", height: "100%" }}>
          <AspectImage
            ratio={1 / 1}
            src="https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cHJvZmlsZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80"
            alt={author}
            sx={{ height: "100%", objectFit: "cover" }}
          />
        </Box>
        <Box
          sx={{
            flex: "1",
            backgroundColor: "isWhite",
            px: 3,
            // height: "100%",
            transition: "all .5s",
          }}
        >
          <Heading as="h4" sx={{ fontSize: 3, fontWeight: 400 }}>
            {author}
          </Heading>
          <Heading
            as="h5"
            sx={{
              fontSize: 1,
              fontWeight: 400,
              color: "muted",
              fontStyle: "italic",
            }}
          >
            {pickRandomTitle()}
          </Heading>
        </Box>
      </Card>
    </Link>
  )
}

export default AuthorCard
