const slugify = require("slugify")
const { CLOUDINARY_BASE } = require("../constants")

/**
 * @param {arr} List - Removes all duplicates from an array
 */
const removeDuplicates = arr => {
  let store = {} // Store for unique items

  if (!Array.isArray(arr)) {
    console.error(
      "The function removeDuplicates did not receive an array as a parameter."
    )
    return []
  }

  // Filter out based on if it's in the store or not
  const authorsFiltered = arr.filter(itm =>
    store[itm] ? false : (store[itm] = itm && true)
  )

  return authorsFiltered
}

const slugifyLower = str => slugify(str, { lower: true })

const getCloudinaryImage = id => `${CLOUDINARY_BASE}/${id}`

const cloudinaryOptimizer = (img, width = "600") => {
  const optimizeStr = `w_${width}/q_auto/f_auto/`
  if (img.includes("upload/")) {
    return img.replace("upload/", `upload/${optimizeStr}`)
  }
  return img.replace("gonation/", `gonation/${optimizeStr}`)
}

module.exports = {
  removeDuplicates,
  slugifyLower,
  getCloudinaryImage,
  cloudinaryOptimizer,
}
